import React, { useState, useCallback, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import {
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  PERMISSIONS,
  SIZES,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsEmptyString,
  AcSortBy,
  AcFormatInternalURI,
  AcFormatDate,
  AcGetEquipmentIcon,
} from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web';

// Imports => Components
import AcCardContextualMenu from '@components/ac-card-contextual-menu/ac-card-contextual-menu.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
  MAIN: 'ac-configuration-card',
  TITLE: 'ac-configuration-card__title',
  CONTENT: 'ac-configuration-card__content',
  SECTION: {
    MAIN: 'ac-configuration-card__section',
    HEADER: 'ac-configuration-card__header',
    FOOTER: 'ac-configuration-card__footer',
    TITLE: 'ac-configuration-card__section__title',
  },
  ROW: {
    MAIN: 'ac-configuration-card__row',
    ITEM: 'ac-configuration-card__row__item',
    ACTION: 'ac-configuration-card__row__item--action',
    EMPTY: 'ac-configuration-card__row__item--empty',
    FAULTY: 'ac-configuration-card__row__item--faulty',
  },
};

const AcConfigurationCard = ({
  id,
  name,
  equipment,
  users,
  last_send,
  last_send_by,
  expires_at,
  config_download_available,
  download,
  cast,
  cast_unavailable,
  remove,
  assign,
  unassign,
  company,
}) => {
  const { can, cannot } = usePermissions();

  const hasControlUnit = useMemo(() => {
    const collection = equipment;
    const len = collection.length;
    let n = 0;
    let result = false;

    for (n; n < len; n++) {
      const item = collection[n];
      const { equipment_type } = item;

      if (!equipment_type || !equipment_type.group) continue;

      const type = equipment_type.group
        .replace('-', '_')
        .replace(/ /g, '_')
        .toLowerCase();

      if (type === KEYS.CONTROL_UNITS || type === KEYS.CONTROL_UNIT) {
        result = true;
      }
    }

    return result;
  }, [equipment]);

  const handleSendToControlUnit = () => {
    if (!hasControlUnit) {
      if (cast_unavailable) cast_unavailable('control-unit');
    } else if (hasControlUnit) {
      if (users.length === 0) {
        cast_unavailable('users');
        return;
      }
      if (cast) cast({ id, name, expires_at });
    }
  };

  const handleDownloadConfigurationFile = (available) => {
    if (!available) return;
    if (download) download({ id, name });
  };

  const handleRemoveConfigurationFromProject = () => {
    if (remove) remove({ id, name });
  };

  const handleAddOperatorToConfiguration = () => {
    if (assign) assign({ id, name });
  };

  const handleRemoveOperatorFromConfiguration = (operator) => {
    if (unassign) unassign({ id, name }, operator);
  };

  const getRowItemClassNames = useCallback((type) => {
    return clsx(
      _CLASSES.ROW.ITEM,
      type && type === 'action' && _CLASSES.ROW.ACTION,
      type && type === 'empty' && _CLASSES.ROW.EMPTY,
      type && type === 'faulty' && _CLASSES.ROW.FAULTY
    );
  }, []);

  const getRowClassNames = useMemo(() => {
    return clsx(_CLASSES.ROW.MAIN);
  }, []);

  const getFooterClassNames = useMemo(() => {
    return clsx(_CLASSES.SECTION.MAIN, _CLASSES.SECTION.FOOTER);
  }, []);

  const getHeaderClassNames = useMemo(() => {
    return clsx(_CLASSES.SECTION.MAIN, _CLASSES.SECTION.HEADER);
  }, []);

  const getSectionTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.SECTION.TITLE);
  }, [name]);

  const getSectionClassNames = useMemo(() => {
    return clsx(_CLASSES.SECTION.MAIN);
  }, []);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.TITLE);
  }, [name]);

  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT);
  });

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const getAddOperatorToConfigurationButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      size: SIZES.SMALL,
      title: 'Add operator(s)',
      callback: handleAddOperatorToConfiguration,
    };
  }, [id]);

  const renderContextMenu = useMemo(() => {
    const route = AcFormatInternalURI(
      { id, entity: KEYS.CONFIGURATION },
      'View details'
    );
    const collection = [
      [
        {
          label: 'View details',
          icon: ICONS.DEVICE_HUB,
          type: TYPES.LINK,
          disabled: cannot(PERMISSIONS.CONFIGURATION.READ),
          to: route,
        },
      ],
      [
        {
          label: 'Send to control unit',
          icon: ICONS.CAST,
          callback: handleSendToControlUnit,
          disabled: cannot(PERMISSIONS.CONFIGURATION.MANAGE),
        },
      ],
      can(PERMISSIONS.CONFIGURATION.UPDATE) && [
        {
          label: 'Remove from project',
          icon: ICONS.TRASH_CAN_OUTLINE,
          type: TYPES.DELETE,
          callback: handleRemoveConfigurationFromProject,
          disabled: cannot(PERMISSIONS.CONFIGURATION.MANAGE),
        },
      ],
    ];

    return <AcCardContextualMenu collection={collection} />;
  }, [id]);

  const renderEquipment = useMemo(() => {
    if (!AcIsSet(equipment)) return null;

    let result = [];

    if (equipment.length > 0) {
      let collection = equipment.map((item) => ({
        ...item,
        equipment_group: item.equipment_type.group,
      }));
      collection = AcSortBy({
        collection,
        field: 'equipment_group',
        direction: 'asc',
      });
      const len = collection.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = collection[n];

        const { id, equipment_group, equipment_type, object_no } = item;

        const route = AcFormatInternalURI(
          { id, entity: equipment_group },
          object_no
        );

        const e_icon = AcGetEquipmentIcon(equipment_group);
        let icon = '';
        if (e_icon) icon = `<i class="ac-icon ac-icon--${e_icon}"></i>`;

        let tableColumns = [
          <AcColumn
            key={`ac-configuration-equipment-${item.id}-icon`}
            xs={12}
            sm={4}
            className={getRowItemClassNames()}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: `${icon}${equipment_group}`,
              }}
            />
          </AcColumn>,
          <AcColumn
            key={`ac-configuration-equipment-${item.id}-object`}
            xs={12}
            sm={4}
            className={getRowItemClassNames()}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: object_no,
              }}
            />
          </AcColumn>,
          <AcColumn
            key={`ac-configuration-equipment-${item.id}-name`}
            xs={12}
            sm={4}
            className={getRowItemClassNames()}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: equipment_type.name,
              }}
            />
          </AcColumn>,
        ];

        if (item.has_detail_page) {
          tableColumns.push(
            <AcColumn
              key={`ac-configuration-equipment-${item.id}-action`}
              xs={12}
              sm={1}
              className={getRowItemClassNames('action')}
            >
              <AcIcon icon={ICONS.CHEVRON_RIGHT} />
            </AcColumn>
          );

          result.push(
            <Link
              key={`ac-configuration-equipment-${item.id}`}
              to={route}
              title={`View ${object_no} details`}
              className={getRowClassNames}
            >
              {tableColumns}
            </Link>
          );
        } else {
          result.push(
            <span
              key={`ac-configuration-equipment-${item.id}`}
              className={getRowClassNames}
            >
              {tableColumns}
            </span>
          );
        }
      }
    } else {
      const empty = (
        <AcRow
          noGutters
          className={getRowClassNames}
          key={`ac-configuration-no-equipment`}
        >
          <AcColumn xs={12} sm={10} className={getRowItemClassNames('empty')}>
            <p
              className={'h-padding-x-15'}
              dangerouslySetInnerHTML={{
                __html:
                  'No equipment has been found or assigned to this configuration.',
              }}
            />
          </AcColumn>
        </AcRow>
      );
      result.push(empty);
    }

    return (
      <div className={getSectionClassNames}>
        <AcExpandable title={'Equipment'}>{result}</AcExpandable>
      </div>
    );
  }, [equipment]);

  const renderOperators = useMemo(() => {
    if (!AcIsSet(users)) return null;

    let result = [];
    const icon = `<i class="ac-icon ac-icon--account-outline"></i>`;

    const basicOperator = {
      username: company?.basic_operator_username,
      password: company?.basic_operator_password,
    };

    let BasicOperatorLine = (
      <AcRow
        className={`${getRowClassNames} h-margin-x-0`}
        key={`ac-configuration-operator-default`}
      >
        <AcColumn xs={12} className={getRowItemClassNames('faulty')}>
          <p
            dangerouslySetInnerHTML={{
              __html: `${icon} Default Operator is not configured. Change these settings under your Company Settings.`,
            }}
          />
        </AcColumn>
      </AcRow>
    );

    if (basicOperator?.username) {
      BasicOperatorLine = (
        <AcRow
          className={`${getRowClassNames} h-margin-x-0`}
          key={`ac-configuration-operator-default`}
        >
          <AcColumn xs={12} className={getRowItemClassNames()}>
            <p
              dangerouslySetInnerHTML={{
                __html: `${icon} ${basicOperator.username} (Default Operator)`,
              }}
            />
          </AcColumn>
        </AcRow>
      );
    }

    result.push(BasicOperatorLine);

    if (users.length > 0) {
      const collection = users;
      const len = collection.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = collection[n];

        const object = (
          <AcRow
            className={`${getRowClassNames} h-margin-x-0`}
            key={`ac-configuration-operator-${item.id}`}
          >
            <AcColumn xs={12} sm={10} className={getRowItemClassNames()}>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${icon}${item.name}`,
                }}
              />
            </AcColumn>
            <AcCheckPermissions allowed={PERMISSIONS.CONFIGURATION.MANAGE}>
              <AcColumn
                xs={12}
                sm={1}
                className={getRowItemClassNames('action')}
              >
                <AcIcon
                  icon={ICONS.PERSON_REMOVE_OUTLINE}
                  title={`Unassign operator ${item.name} from this configuration`}
                  callback={() => handleRemoveOperatorFromConfiguration(item)}
                  className={'h-margin-right-5'}
                />
              </AcColumn>
            </AcCheckPermissions>
          </AcRow>
        );

        result.push(object);
      }
    } else {
      const empty = (
        <AcRow
          className={`${getRowClassNames} h-margin-x-0`}
          key={`ac-configuration-no-operators`}
        >
          <AcColumn xs={12} sm={10} className={getRowItemClassNames('empty')}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  'No operators have been found or assigned to this configuration.',
              }}
            />
          </AcColumn>
        </AcRow>
      );
      result.push(empty);
    }

    let addOperator = null;

    if (can(PERMISSIONS.CONFIGURATION.MANAGE)) {
      addOperator = (
        <AcRow
          noGutters
          className={`${getRowClassNames} h-margin-top-10`}
          key={`ac-configuration-add-operators`}
        >
          <AcColumn xs={12} sm={10} className={getRowItemClassNames()}>
            <AcButton {...getAddOperatorToConfigurationButtonOptions}>
              <AcIcon icon={ICONS.PERSON_ADD_OUTLINE} />
              <span>Add operator(s)</span>
            </AcButton>
          </AcColumn>
        </AcRow>
      );
      // result.push(addOperator);
    }

    return (
      <div className={getSectionClassNames}>
        <AcExpandable title={'Operators'}>{result}</AcExpandable>
        {addOperator}
      </div>
    );
  }, [users, company]);

  const renderLastSentTo = useMemo(() => {
    let result = null;

    if (!AcIsSet(last_send)) {
      const empty = (
        <AcRow noGutters className={getRowClassNames}>
          <AcColumn xs={12} sm={10} className={getRowItemClassNames('empty')}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  'This configuration has not been sent to the control unit yet.',
              }}
            />
          </AcColumn>
        </AcRow>
      );
      result = empty;
    } else {
      const date = AcFormatDate(last_send, null, DATETIME_FORMATS.DATE);
      const time = AcFormatDate(last_send, null, DATETIME_FORMATS.TIME_UTC);
      const sent_by =
        AcIsSet(last_send_by) && !AcIsEmptyString(last_send_by)
          ? last_send_by
          : 'Unknown user';
      const available = config_download_available;
      result = (
        <AcRow noGutters className={getRowClassNames}>
          <AcColumn xs={12} sm={3} className={getRowItemClassNames()}>
            <p
              dangerouslySetInnerHTML={{
                __html: date,
              }}
            />
          </AcColumn>
          <AcColumn xs={12} sm={3} className={getRowItemClassNames()}>
            <p
              dangerouslySetInnerHTML={{
                __html: time,
              }}
            />
          </AcColumn>
          <AcColumn xs={12} sm={3} className={getRowItemClassNames()}>
            <p
              dangerouslySetInnerHTML={{
                __html: `by <span rel="${sent_by}">${sent_by}</span>`,
              }}
            />
          </AcColumn>
          <AcColumn xs={12} sm={1} className={getRowItemClassNames('action')}>
            <AcIcon
              icon={ICONS.FILE_DOWNLOAD_OUTLINE}
              callback={() => handleDownloadConfigurationFile(available)}
              title={
                available
                  ? 'Download configuration file'
                  : 'Configuration file is not available for download'
              }
              disabled={!available}
            />
          </AcColumn>
        </AcRow>
      );
    }

    return (
      <div className={getSectionClassNames}>
        <p className={getSectionTitleClassNames}>
          Configuration file last sent to control unit
        </p>
        {result}
      </div>
    );
  }, [last_send, last_send_by, config_download_available]);

  return (
    <AcCard className={getMainClassNames} flat>
      <div className={getContentClassNames}>
        <div className={getHeaderClassNames}>
          {name && (
            <AcHeading rank={6} className={getTitleClassNames}>
              {name}
            </AcHeading>
          )}
          {renderContextMenu}
        </div>

        {renderEquipment}

        {renderOperators}

        {renderLastSentTo}
      </div>
    </AcCard>
  );
};

export default memo(AcConfigurationCard);
