import React, { useCallback, useMemo, memo } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE, ICONS } from '@constants';

// Imports => Components
const AcBreadcrumbs = loadable(() =>
	import('@components/ac-breadcrumbs/ac-breadcrumbs.web')
);
const AcMessageCenter = loadable(() =>
	import('@components/ac-message-center/ac-message-center.web')
);
const AcSupportCenter = loadable(() =>
	import('@components/ac-support-center/ac-support-center.web')
);
const AcAccountSwitch = loadable(() =>
	import('@components/ac-account-switch/ac-account-switch.web')
);
const AcSubNavigation = loadable(() =>
	import('@components/ac-sub-navigation/ac-sub-navigation.web')
);

// Imports => Atoms
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-header',
	WHITE: 'ac-header--white',
	ALPHA: 'ac-header--alpha',
	OMEGA: 'ac-header--omega',
};

const AcHeader = ({
	children,
	withBreadcrumbs = true,
	withMessageCenter = true,
	withSupportCenter = true,
	withNavigation = true,
	withCompanySwitch = true,
	theme,
}) => {
	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
	}, [theme]);

	const renderBreadcrumbs = useMemo(() => {
		return <AcBreadcrumbs />;
	}, [withBreadcrumbs]);

	const renderMessageCenter = useMemo(() => {
		return <AcMessageCenter />;
	}, [withMessageCenter]);

	const renderSupportCenter = useMemo(() => {
		return <AcSupportCenter />;
	}, [withSupportCenter]);

	const renderCompanySwitch = useMemo(() => {
		return <AcAccountSwitch />;
	}, [withCompanySwitch]);

	const renderNavigation = useMemo(() => {
		return <AcSubNavigation />;
	}, [withNavigation]);

	return (
		<header className={getMainClassNames} id={'ac-header'}>
			{withBreadcrumbs && renderBreadcrumbs}
			{withMessageCenter && renderMessageCenter}
			{withSupportCenter && renderSupportCenter}
			{withCompanySwitch && renderCompanySwitch}
			{withNavigation && renderNavigation}
		</header>
	);
};

export default memo(AcHeader);
